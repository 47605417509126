import { FlowEditorSDK } from '@wix/yoshi-flow-editor';
import type {
  WidgetInstallationType,
  addWidgetOptions,
} from '@wix/platform-editor-sdk';
import { BalanceWidgetData } from '../consts';

const DEFAULT_WIDGET_LAYOUT = { height: 31, width: 235, x: 55, y: 55 };

const widgetOptions: addWidgetOptions = {
  widgetId: BalanceWidgetData.ID,
  installationType: 'closed' as WidgetInstallationType,
  layout: DEFAULT_WIDGET_LAYOUT,
  scopedPresets: {
    desktop: {
      style: BalanceWidgetData.preset,
      layout: BalanceWidgetData.preset,
    },
  },
  layouts: {
    componentLayout: {
      maxWidth: {
        type: 'percentage',
        value: 100,
      },
      hidden: false,
      height: {
        type: 'auto',
      },
      type: 'ComponentLayout',
      width: {
        type: 'maxContent',
      },
    },
    type: 'SingleLayoutData',
    itemLayout: {
      position: 'relative',
      id: '',
      alignSelf: 'center',
      gridArea: {
        rowStart: 1,
        rowEnd: 2,
        columnStart: 1,
        columnEnd: 2,
      },
      justifySelf: 'center',
      type: 'GridItemLayout',
    },
  },
};

export const addBalanceWidgetToStage = async (editorSDK: FlowEditorSDK) => {
  await editorSDK.application.appStudioWidgets.addWidget('', {
    ...widgetOptions,
  });
};

export const handleFirstInstall = async (editorSDK: FlowEditorSDK) => {
  addBalanceWidgetToStage(editorSDK);
};
