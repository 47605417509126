import {
  FlowEditorSDK,
  WidgetBuilder,
  ComponentRef,
  GetWidgetManifestFn,
  FlowAPI,
} from '@wix/yoshi-flow-editor';
import { WidgetRoles, WidgetGFPPActions } from './consts';
import { SPECS } from '../../consts';

const setWidgetGFPP = (
  widgetBuilder: WidgetBuilder,
  editorSDK: FlowEditorSDK,
  flowAPI: FlowAPI,
) => {
  widgetBuilder
    .gfpp()
    .set(WidgetGFPPActions.MAIN_ACTION, {
      target: {
        role: WidgetRoles.Content,
      },
    })
    .set(WidgetGFPPActions.LAYOUT, {
      target: {
        role: WidgetRoles.Content,
      },
    })
    .set(WidgetGFPPActions.ADD, { behavior: 'HIDE' })
    .set(WidgetGFPPActions.CONNECT, { behavior: 'HIDE' });

  widgetBuilder.configureConnectedComponents(
    WidgetRoles.Content,
    (connectedComponents) => {
      connectedComponents
        .set({
          displayName: flowAPI.translations.t('creditWidget.layersPanel.label'),
        })
        .gfpp()
        .set(WidgetGFPPActions.CONNECT, { behavior: 'HIDE' })
        .set(WidgetGFPPActions.ADD, { behavior: 'HIDE' })
        .set(WidgetGFPPActions.LINK, { behavior: 'HIDE' })
        .set(WidgetGFPPActions.MAIN_ACTION, {
          label: flowAPI.translations.t('creditWidget.editWidget.gfpp.label'),
          onClick: (event) => {
            const { componentRef } = event.detail;
            openSettingsWidgetMenu(editorSDK, componentRef);
          },
        })
        .set(WidgetGFPPActions.LAYOUT, {
          onClick: (event) => {
            const { componentRef } = event.detail;
            openLayoutWidgetMenu(editorSDK, componentRef);
          },
        });
    },
  );
};

const openSettingsWidgetMenu = (
  editorSDK: FlowEditorSDK,
  componentRef: ComponentRef,
) => {
  editorSDK.editor.openNativeComponentPanel<'StylableButton'>(
    'token',
    'settings',
    {
      componentRef,
      configuration: {
        controls: {
          link: { hidden: true },
          label: { hidden: true },
          icon: {},
        },
        controlGroups: {},
        states: {},
      },
    },
  );
};

const openLayoutWidgetMenu = (
  editorSDK: FlowEditorSDK,
  componentRef: ComponentRef,
) => {
  editorSDK.editor.openNativeComponentPanel<'StylableButton'>(
    'token',
    WidgetGFPPActions.LAYOUT,
    {
      componentRef,
    },
  );
};

export const getWidgetManifest: GetWidgetManifestFn = (
  widgetBuilder,
  editorSDK,
  flowAPI,
) => {
  const { experiments } = flowAPI;

  if (!experiments.enabled(SPECS.RiseAppBalanceWidget)) {
    return;
  }

  widgetBuilder.set({
    displayName: flowAPI.translations.t('creditWidget.layersPanel.label'),
    nickname: 'balance-widget',
  });
  setWidgetGFPP(widgetBuilder, editorSDK, flowAPI);
  return widgetBuilder.build();
};
