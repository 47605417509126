import { EditorReadyFn, OnEventFn } from '@wix/yoshi-flow-editor';
import { AppManifest } from '@wix/app-manifest-builder';
import { fetchWidgetsStageData } from '@wix/bob-widget-services';
import { handleFirstInstall } from './editor-app/firstInstallHandler';
import { SPECS } from './consts';

export const editorReady: EditorReadyFn = async (
  editorSDK,
  appDefId,
  options,
  flowAPI,
) => {
  const { experiments, reportError } = flowAPI;
  try {
    if (options.firstInstall) {
      experiments.enabled(SPECS.RiseAppBalanceWidget) &&
        (await handleFirstInstall(editorSDK));

      // TODO: report BI first install & add widget to stage
    }
  } catch (e) {
    reportError(e as Error);
  }
};

// @ts-expect-error
export const getAppManifest = async (options): Promise<AppManifest> => {
  const { appManifestBuilder } = options;
  const baseManifest = await fetchWidgetsStageData(options);
  return appManifestBuilder.withJsonManifest(baseManifest).build();
};

export const onEvent: OnEventFn = async (event) => {
  switch (event.eventType) {
    case 'componentDeleted':
      // TODO: report BI widget deleted
      break;
    default:
      return;
  }
};
